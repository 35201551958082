import React from 'react'
import { Link } from 'react-router-dom';
import Box from '../../components/Box';
import contactData from './contactData';
import Contact from './Contact';
import { Container, InnerContainer } from '../../components/Container';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <Container
      css={{
        marginTop: '5em',
        fontSize: 12,
        background: '#0D0E0E',
        color: '#AAACAE',
        padding: '3em 2em',
        '@md': {
          fontSize: 14,
          padding: '3em 0',
        }
      }}
    >
      <InnerContainer
        css={{
          margin: '20px 0',
          display: 'flex',
          flexDirection: 'column',
          gap: '3em',
          'img': {
            height: 20
          },
        }}
      >
        <Box>
          <img className='logo' src="/logo.png" alt="logo" />
        </Box>
        <Box
          css={{
            display: 'grid',
            gap: 30,
            gridTemplateColumns: '1fr',
            '@md': {
              gridTemplateColumns: '1fr 1fr',
            },
            '@lg': {
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
            },
          }}
        >
            {contactData.map(x => (
              <Contact key={x.flag} data={x} />
            ))}
        </Box>
        <Box
          css={{
            borderTop: '1px solid #FFFFFF26',
            width: '100%'
          }}
        />
        <Box
          css={{
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: '1fr',
            gap: '2em',
            '@md': {
              gridTemplateColumns: '1fr auto',
              gap: '0',
            }
          }}
        >
          <Box
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10
            }}
          >
            <Box
              css={{
                display: 'flex',
                '.menu': {
                  padding: '0px 8px',
                  borderRight: '1px solid #AAACAE',
                  display: 'flex',
                  alignItems: 'center',
                  'a': {
                    padding: 0,
                    color: 'inherit',
                    textDecoration: 'none'
                  },
                  '&:last-child': {
                    borderRight: 0,
                  },
                  '&:first-child': {
                    paddingLeft: 0,
                  }
                }
              }}
            >
              <Box className='menu'><Link to="/terms-of-use">Terms of Use</Link></Box>
              <Box className='menu'><Link to="/privacy-policy">Privacy Policy</Link></Box>
              <Box className='menu'><Link to="/operation-policy">Operation Policy</Link></Box>
            </Box>
            <Box>Copyright © {currentYear} INNER CIRCLE. All Right Reserved.</Box>
          </Box>
          <Box
            css={{
              display: 'flex',
              gap: 15,
              '@md': {
                justifyContent: 'right',
              }
            }}
          >
            <a href="#"><img src="/social/facebook_icon.png" alt="facebook icon" /></a>
            <a href="#"><img src="/social/whatsapp_icon.png" alt="whatsapp icon" /></a>
            <a href="#"><img src="/social/instagram_icon.png" alt="instagram icon" /></a>
          </Box>
        </Box>
      </InnerContainer>
    </Container>
  )
}

export default Footer;