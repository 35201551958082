import React from 'react'
import Box from '../components/Box'

const Wrapper = ({ children }) => {
    return (
        <Box
            css={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            {children}
        </Box>
    )
}

export default Wrapper