import { lazy } from 'react';

const menuData = [
    {
        path: '/',
        component: lazy(() => import('../pages/Home')),
        exact: true,
    },
    {
        path: '/terms-of-use',
        component: lazy(() => import('../pages/TermsOfUse')),
    },
    {
        path: '/privacy-policy',
        component: lazy(() => import('../pages/PrivacyPolicy')),
    },
    {
        path: '/operation-policy',
        component: lazy(() => import('../pages/OperationPolicy')),
    },
]

export default menuData;