import React, { Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import menuData from './menuData';
import Wrapper from './Wrapper';

const NoMatch = () => {
    return (
        <div>404 Not Found</div>
    )
}

const LayoutRoutes = () => {
    return (
        <Suspense fallback={<div />}>
            <Switch>
                {menuData.map(x => <Route key={x.path} path={x.path} component={x.component} exact={!!x.exact} />)}
                <Route path="*" component={NoMatch} />
            </Switch>
        </Suspense>
    )
}

const MainLayout = () => {
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Wrapper>
            <Header />
            <LayoutRoutes />
            <Footer />
        </Wrapper>
    );
}

export default MainLayout;