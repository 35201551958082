import React from 'react'
import Box from '../../components/Box'

const Contact = ({ data }) => {
    return (
        <Box
            css={{
                display: 'flex',
                flexDirection: 'column',
                gap: 5
            }}
        >
            <Box
                css={{
                    display: 'flex',
                    gap: 10,
                    alignItems: 'center',
                }}
            >
                <img src={`/footer/flag-${data.flag}.png`} alt="flag" />
                <b>{data.name}</b>
            </Box>
            <Box
                css={{
                    display: 'flex',
                    gap: 10,
                    alignItems: 'center',
                }}
            >
                <img src={`/footer/icon-phone.png`} alt="phone" />
                <span>{data.phone}</span>
            </Box>
            <Box
                css={{
                    display: 'flex',
                    gap: 10,
                    alignItems: 'center',
                    'a': {
                        color: 'inherit'
                    }
                }}
            >
                <img src={`/footer/icon-email.png`} alt="email" />
                <a href={`mailto:${data.email}`}><span>{data.email}</span></a>
            </Box>
        </Box>
    )
}

export default Contact