const contactData = [
    {
        flag: 'singapore',
        name: 'IRON UNITED PTE. LTD.',
        phone: '+65-9057-4480',
        email: 'info@ironman.sg',
    },
    {
        flag: 'indonesia',
        name: 'IRON ASSET INDONESIA PT.',
        phone: '+62-822-1111-0365',
        email: 'info@ironman.co.id',
    },
    {
        flag: 'south-korea',
        name: 'IRON ASSET KOREA CO., LTD.',
        phone: '+82-70-4322-2366',
        email: 'info@ironman.co.kr',
    },
    {
        flag: 'japan',
        name: 'IRON ASSET JAPAN CO., LTD.',
        phone: '+81-80-9057-0365',
        email: 'info@ironman.us',
    },
];

export default contactData;