import React from 'react'
import Box from '../components/Box';
import { Link } from 'react-router-dom';
import { Container, InnerContainer } from '../components/Container';

const Header = () => {
  return (
    <Container
      css={{
        background: '#000000',
        'img': {
          height: 20
        }
      }}
    >
      <InnerContainer>
        <Box
          css={{
            margin: '20px 0',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
          }}
        >
          <Link to="/"><img src="/logo.png" alt="logo" /></Link>
          <Box
            css={{
              display: 'flex',
              gap: 15,
              justifyContent: 'right',
            }}
          >
            <a href="#"><img src="/social/facebook_icon.png" alt="facebook icon" /></a>
            <a href="#"><img src="/social/whatsapp_icon.png" alt="whatsapp icon" /></a>
            <a href="#"><img src="/social/instagram_icon.png" alt="instagram icon" /></a>
          </Box>
        </Box>
      </InnerContainer>
    </Container>
  );
}

export default Header;